/* eslint-disable */
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import HomeFooter from "examples/Footers/HomeFooter/HomeFooter";

// Presentation page sections
import InfoCards from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";

// Material Kit 2 React themes

import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import React, { useState, useEffect } from 'react';
// Presentation page components

// Routes

// Images
import bgImage from "assets/images/BackSVG3.svg";
import bgImageDark from "assets/images/dark.svg";
import { useMediaQuery, createTheme, CircularProgress } from '@mui/material';

import TypingText from './components/TypingText';
import { useLightMode } from 'LightModeContext';


function Presentation() {

  const theme = createTheme(); // You can customize the theme if needed
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const textSize = isMobile ? '14px' : '18px';
  const { lightMode, setLightMode } = useLightMode();

  const toggleMode = () => {
    setLightMode((prevMode) => !prevMode);

  };

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    // Callback function to be called when the iframe has loaded
    setIframeLoaded(true);
  };





  const LightModeSwitchingIframe = ({ lightMode, onIframeLoad }) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
      // Change the key to trigger a remount when lightMode switches
      setKey((prevKey) => prevKey + 1);
    }, [lightMode]);

    const handleIframeLoad = () => {
      // Notify the parent component that the iframe has loaded
      onIframeLoad();
    };


    return (
      <iframe
        key={key}
        src={lightMode ? "https://lottie.host/embed/0f6d0529-4f0c-4269-bd95-1cb0474689a3/eXrDGFSC9u.json" :
          "https://lottie.host/embed/a7c7762c-af6e-4e44-9774-152e6438decc/uzoTRETLWq.json"}
        style={{
          width: isMobile ? '65vh' : '120vh',
          height: isMobile ? '65vh' : '120vh',
          position: 'absolute',
          top: isMobile ? 0 - '65vh' : 0,
          left: isMobile ? 0 - '65vh' : 0 - '110vh',
          border: 'none',
          
        }}
        onLoad={handleIframeLoad}
      ></iframe>
    );
  };

  const DelayedTypingText = ({ text, speed, textSize, startupDelay }) => {
    const [showTyping, setShowTyping] = useState(false);

    useEffect(() => {
      const delayTimer = setTimeout(() => {
        setShowTyping(true);
      }, startupDelay);

      return () => clearTimeout(delayTimer); // Cleanup the timer on component unmount

    }, [startupDelay]);

    // return (
    //   <div>
    //     {showTyping && (
    //       <TypingText text={text} speed={speed} textSize={textSize} />
    //     )}
    //   </div>
    // );
  };

  const HomeUISecondSection = () => {
    const [loaded, setLoaded] = useState(false);


    if (iframeLoaded) {

      useEffect(() => {
        const delayLoad = () => {
          setTimeout(() => {
            setLoaded(true);
          }, 1200);
        };

        delayLoad();
      }, []);

    }

    if (!loaded) {

      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <Card
          sx={{
            borderRadius: 0,
            p: 0,

            mt: isMobile ? -9 : 0,
            //background: lightMode ? `linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(200, 245, 230, 1))` : '#000',
            backgroundColor: lightMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
            backdropFilter: "saturate(50%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >




            <InfoCards
              lightMode={lightMode}
              isMobile={isMobile}
               />

      

          <Card

            sx={{
              p: 2,
              borderRadius: 0,
              background: lightMode ? `linear-gradient(to bottom, #CFEDEE, #E6E9D8)` : '#0B060E',
              //backgroundColor: lightMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
              backdropFilter: "saturate(50%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >

            <Information lightMode={lightMode} color={lightMode ? "#000" : "#FFFF"} />
          </Card>

          <Card
            sx={{
              p: 2,
              borderRadius: 0,
              background: lightMode ? `linear-gradient(to bottom, #EBF8F5, rgba(200, 245, 230, 1))` : '#000A0A',
              //backgroundColor: lightMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
            }}

          >

            <DesignBlocks color={lightMode ? "dark" : "light"} colorDesc={lightMode ? "secondary" : "light"}
              backgroundColor={lightMode ? "rgba(237, 251, 245, 1)" : "#000"} titleColor={lightMode ? "#000" : "#FFFF"}
              videoHeight={isMobile ? "240" : "280"} />
          </Card>


        </Card>
        <MKBox pt={4} px={1} mt={-2} sx={{ backgroundColor: lightMode ? 'rgba(237, 251, 245, 1)' : '#000' }}>
          <HomeFooter setLogoDark={lightMode ? false : true} />
        </MKBox>
      </>
    );
  };

  return (
    <>


      {isMobile && (
        <DefaultNavbar
          mobile={true}
          lightMode={lightMode}
          toggleMode={toggleMode}
        />
      )}

      {!isMobile && (
        <Card>
          <DefaultNavbar
            mobile={isMobile}
            lightMode={lightMode}
            toggleMode={toggleMode}
          />
        </Card>
      )}


      <MKBox
        minHeight={isMobile ? '80vh' : '90vh'}
        width="100%"
        sx={{
          backgroundImage: lightMode ?
            'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'1\' height=\'1\'%3E%3C/svg%3E")'
            : 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'1\' height=\'1\'%3E%3C/svg%3E")',
          backgroundSize: isMobile ? 'cover' : '50%',
          backgroundPosition: 'top',
          backgroundRepeat: isMobile ? 'no-repeat' : 'no-repeat', // Change to 'no-repeat' for desktop view
          backgroundColor: isMobile ? 'transparent' : lightMode ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
          display: 'grid',
          placeItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          transition: isMobile ? 'none' : 'opacity 0.3s ease-in-out',
          '&:hover': {
            opacity: isMobile ? 1 : 1,
          },
        }}
      >
        {/* Add a positioned iframe element */}
        <LightModeSwitchingIframe lightMode={lightMode} onIframeLoad={handleIframeLoad} />
        <MKBox mt={isMobile ? 0 : 15}>

          <div
            sx={{
              position: 'absolute',
              top: '55%', // Adjust the top value to move the text towards the bottom
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: '#fff', // Set text color
              fontFamily: 'Arial, sans-serif', // Set desired font family
            }}
          >
            <DelayedTypingText
              text="Dive into the world of programming..."
              speed={110}
              textSize={textSize}
              startupDelay={2000} // Specify the startup delay in milliseconds (e.g., 2000ms = 2 seconds)
            />
          </div>
        </MKBox>
      </MKBox>
      <HomeUISecondSection />
    </>
  );
}

export default Presentation;
