/* eslint-disable */
import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const VideoCard = ({ videoSrc, title, backgroundColor, titleColor, videoHeight }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Card style={{ backgroundColor: backgroundColor }} >
      <iframe
        width="100%"
        height={videoHeight}
        src={videoSrc}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowFullScreen"
      ></iframe>
      <CardContent >
        <Typography variant="h6" mt={1} style={{ color: titleColor }}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoCard;