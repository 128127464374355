/* eslint-disable */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import SimpleCard from "examples/Cards/SimpleCard";
import SlidingCardCarousel from "examples/Cards/SlidingCardStack/CardCarsouel";

import { useMediaQuery, createTheme } from '@mui/material';

// Images
import bgBackDark from "assets/images/rotateFBCom5.jpg";
import bgBackLight from "assets/images/rotateFBCom4.jpg";

function Information({ color, lightMode }) {

  const theme = createTheme(); // You can customize the theme if needed
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  return (
    <MKBox component="section" py={3} my={1}>
      <Container>
        <Grid container spacing={isMobile ? 6 : 1}  >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>

          {/* <RotatingCard>
              <RotatingCardFront
                
                image={bgBack}
                icon="touch_app"
                title={
                  <>
                    About 
                    <br />
                    Your Instructors
                  </>
                }
                description=""
              />
              <RotatingCardBack
                image={bgBack}
                title1="Assistant Lecturer"
                description2="B.Sc. (Hons) in Computer Science, USJ."
                title2="Instructor"
                description2_2="B.Sc. (Hons) in Computer Science, USJ."
              />
            </RotatingCard> */}

            <SimpleCard
              title="About Your Instructors"
              description="Upeksha Samarasinghe"
              description2="Assistant Lecturer (USJ)."
              description3="B.Sc. (Hons.) (Sp.) in Computer Science, USJ."

              description4="Teaching Experience"
              description5="Assistant Lecturer at Department of Information Technology, FMSC, USJ."
              description6="Teaching Assistant at Department of Computer Science, FAS, USJ. (1y)"

              description7="Industry Experience"
              description8="Software Engineer at Echonlabs (PVT) Limited. (2y)"



              image={bgBackDark} 
            />
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <SlidingCardCarousel color={color} lightMode={lightMode} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
