/* eslint-disable */
// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons

// Pages


// Sections
import Forms from "layouts/sections/input-areas/forms/index";
import ReqAssitForm from "layouts/sections/input-areas/forms/ReqAssistIndex";

const routes = [

  {
    name: "sections",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "input areas",
        description: "See all input areas",
        dropdown: true,
        collapse: [
          {
            name: "forms",
            route: "/request-to-join",
            component: <Forms />,
          },
          {
            name: "forms",
            route: "/request-for-assistance",
            component: <ReqAssitForm />,
          },
        ],
      }
    ]
  }

];

export default routes;
