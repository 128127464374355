/* eslint-disable */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";


import bgBackDark from "assets/images/rotateFBCom5.jpg";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
//import DefaultCounterCard from "examples/Cards/OutlineInfoCards/OutlineInfoCard";
import DetailCard from "examples/Cards/DetailCard";
import sideImg1 from 'assets/images/dc1_1.svg';
import sideImg2 from 'assets/images/dc2_1.svg';
import sideImg3 from 'assets/images/dc3_1.svg';

function Counters({ lightMode, isMobile }) {

  return (
    <MKBox py={0}>

      <DetailCard color={lightMode? "#00004E" : "#00001A"}

        isMobile={isMobile}
        sideImg = {sideImg1}
        left ={true}
        descPadding={isMobile ? 2 : 10}
        bgImg={bgBackDark}
        colorTitle={lightMode ? "warning" : "warning"}
        colorDesc={lightMode ? "white" : "white"}
        heading={"For A/L ICT / B.Sc. & M.Sc."}
        title="Group and Individual Sessions for A/L ICT, B.Sc. & M.Sc. Students in Sri Lanka"
        description="Whether you're preparing for A/L ICT examinations, 
        pursuing a Bachelor's degree, or advancing your studies at the Master's level, 
        our courses are designed to meet your needs and aspirations. Join us on a 
        transformative journey where theory meets practice, 
        and unlock your potential to become a proficient programmer in today's digital age.
"
      />


      <DetailCard color={lightMode? "#5C1700" : "#1D0700"}

        isMobile={isMobile}
        sideImg = {sideImg2}
        left ={false}
        descPadding={isMobile ? 2 : 10}
        bgImg={bgBackDark}
        colorTitle={lightMode ? "warning" : "warning"}
        colorDesc={lightMode ? "white" : "white"}
        heading={"Comprehensive Coverage"}
        title="Theory & Practical"
        description="Covering theoretical aspects with practical 
      exposure involving real-world projects to ensure a 
      seamless transition from theory to application. Immerse yourself in hands-on coding challenges 
      and collaborative learning environments.
      "
      />

      <DetailCard color={lightMode? "#00004E" : "#00001A"}

        isMobile={isMobile}
        sideImg = {sideImg3}
        descPadding={isMobile ? 2 : 10}
        bgImg={bgBackDark}
        left ={true}
        colorTitle={lightMode ? "warning" : "warning"}
        colorDesc={lightMode ? "white" : "white"}
        heading={"Expert Guidance & Tutoring"}
        title="Experienced University Instructors"
        description="Learn from seasoned instructors with industry experience, 
        providing insights and mentorship 
        for your programming endeavours along with gaining expertise to ace your exams.
"
      />






      {/* <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard color={lightMode ? "dark" : "light"}
              colorTitle={lightMode ? "darkgreen" : "warning"}
              colorDesc={lightMode ? "black" : "textDarkBlue"}
              heading={"Comprehensive Coverage"}
              title="Theory & Practical"
              description="Covering theoretical aspects with practical 
              exposure involving real-world projects to ensure a 
              seamless transition from theory to application. Immerse yourself in hands-on coding challenges 
              and collaborative learning environments."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard color={lightMode ? "dark" : "light"}
              colorTitle={lightMode ? "darkgreen" : "warning"}
              colorDesc={lightMode ? "black" : "textDarkBlue"}
              heading={"Expert Guidance & Tutoring"}
              title="Experienced University Instructors"
              description="Learn from seasoned instructors with industry experience, 
              providing insights and mentorship 
              for your programming endeavours along with gaining expertise to ace your exams."
            />
          </Grid>
        </Grid>
      </Container> */}
    </MKBox>
  );
}

export default Counters;
