/* eslint-disable */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Link } from 'react-router-dom';
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useMediaQuery, createTheme } from '@mui/material';
import logoImg from "assets/images/codeaLogo.png"
import logoImgDark from "assets/images/codeaLogoDark.png"
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function HomeFooter({ socials, light, setLogoDark }) {


  const renderSocials = socials.map((social) => (
    <MKTypography
      key={social.link}
      component={MuiLink}
      href={social.link}
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {social.icon}
    </MKTypography>
  ));

  const theme = createTheme(); // You can customize the theme if needed
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const openExternalLinks = () => {
    // Define an array of external URLs you want to open
    const externalLinks = [
      'https://www.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_4102879.htm#query=programming&position=3&from_view=search&track=sph&uuid=f045d039-5258-402d-94aa-bcb9c1ad6650#position=3&query=programming',
      'https://www.freepik.com/free-vector/hand-coding-concept-illustration_21864184.htm#query=computer%20programming&position=33&from_view=search&track=ais&uuid=debacf1b-3419-43a0-9452-104620d82c42',
      "https://www.freepik.com/free-vector/webinar-concept-illustration_12325510.htm#page=2&query=education&position=16&from_view=author&uuid=7b79a7de-d8aa-484b-b839-8c28e75b4575",
      "https://www.freepik.com/free-vector/thesis-concept-illustration_19466808.htm#query=education&position=21&from_view=author&uuid=ea18b53d-29ca-4920-80a5-f017e824b6f1",
      "https://www.freepik.com/free-vector/programming-concept-illustration_7118756.htm#query=programming&position=0&from_view=author&uuid=8f3f770f-d266-4148-a724-efd741fd4123"
      // Add more URLs as needed
    ];

    // Open each link in a new tab or window
    externalLinks.forEach(link => {
      window.open(link, '_blank');
    });
  };

  return (
    <MKBox component="footer" py={4} >
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>

          <MKBox justifyContent="space-between" alignItems="center">
            <MKBox
              component={Link}
              to="/"
              lineHeight={1}
              alignItems="center"
            >

              <img
                src={setLogoDark ? logoImgDark : logoImg}
                style={{
                  opacity: 0.5,
                  width: isMobile ? '10%' : '5%',
                }}

              />
            </MKBox>
          </MKBox>


          <MKTypography variant="h4" color={light ? "white" : "secondary"}>
            Codea Academy
          </MKTypography>
        </Grid>

        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <Stack display="flex" direction="row" justifyContent="center" spacing={3} mt={3} mb={1}>
            {renderSocials}
          </Stack>
          <MKTypography variant="body2" color={light ? "white" : "secondary"}>
            +94 71 328 3694
          </MKTypography>
          <MKTypography variant="body2" color={light ? "white" : "secondary"}>
            info.codeaacademy@gmail.com
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }} mt={3}>
          <MKTypography variant="h6" color={light ? "white" : "secondary"}>
            About
          </MKTypography>
          <MKTypography variant="body2" color={light ? "white" : "secondary"}>
            Developed by Codea Dev.
          </MKTypography>
          <a href="https://www.linkedin.com/in/thisura-em">
            <MKTypography variant="body2" color={light ? "footerText" : "footerText"}>
              Thisura Embuldeniya (Co-Developer)
            </MKTypography>
          </a>
          <a href="https://www.linkedin.com/in/upeksha-samarasinghe/">
          <MKTypography variant="body2" color={light ? "footerText" : "footerText"}>
            Upeksha Samarasinghe (Co-Developer)
          </MKTypography>
          </a>


          <MKTypography
            variant="body2"
            color={light ? "white" : "secondary"}>
            Graphics Credits:
          </MKTypography>

          <MKTypography
            variant="body2"
            color={light ? "footerText" : "footerText"}
            onClick={openExternalLinks}
            style={{ cursor: 'pointer' }}>
            fullvector / storyset  on Freepik.com
          </MKTypography>
          <MKTypography
            variant="body2"
            color={light ? "white" : "secondary"}>
           
          </MKTypography>

        </Grid>
      </Grid>
    </MKBox>


  );
}

// Setting default values for the props of CenteredFooter
HomeFooter.defaultProps = {
  socials: [
    { icon: <FacebookIcon fontSize="small" />, link: "" },
    {
      icon: <TwitterIcon fontSize="small" />,
      link: "",
    }

  ],
  light: false,
};

// Typechecking props for the CenteredFooter
HomeFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  socials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  light: PropTypes.bool,
};

export default HomeFooter;
