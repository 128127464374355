import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { LightModeProvider } from "./LightModeContext";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <LightModeProvider>
      <App />
    </LightModeProvider>
  </BrowserRouter>
);
