/* eslint-disable */
// prop-types is a library for typechecking of props
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import { useLightMode } from 'LightModeContext';

// Routes
import routes from "routes";

function BaseLayout({ children }) {

  const { lightMode, setLightMode } = useLightMode();

  const toggleMode = () => {
    setLightMode((prevMode) => !prevMode);
  };

  return (
    <MKBox display="flex" flexDirection="column" 
    bgColor={lightMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 30, 30, 1)'} minHeight="100vh">
      <MKBox bgColor={lightMode ? 'white' : 'black'} shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: ""
          }}
          transparent
          relative
          lightMode={lightMode}
          toggleMode={toggleMode}
        />
      </MKBox>
      <Container sx={{ mt: -2 }}>
        <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          {children}
        </Grid>
      </Container>
      <MKBox mt="auto">
        <CenteredFooter />
      </MKBox>
    </MKBox>
  );
}
export default BaseLayout;
