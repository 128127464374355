/* eslint-disable */
// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
// Forms page components
import FormSimple from "layouts/sections/input-areas/forms/components/FormSimple";
// Forms page components code

function Forms() {
  return (
    <BaseLayout
    >
      <FormSimple />
    </BaseLayout>
  );
}

export default Forms;
