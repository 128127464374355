/* eslint-disable */
import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import logoImg from "assets/images/codeaLogo.png"
import logoImgDark from "assets/images/codeaLogoDark.png"
import Switch from '@mui/material/Switch';

import breakpoints from "assets/theme/base/breakpoints";

function DefaultNavbar({ brand, transparent, light, sticky, relative, mobile, lightMode, toggleMode }) {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);



  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        //setMobileView(true);
        //setMobileNavbar(false);
      } else {
        //setMobileView(false);
        //setMobileNavbar(false);
      }
    }


    window.addEventListener("resize", displayMobileNavbar);

    displayMobileNavbar();

    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);





  // Render routes that are nested inside the dropdown menu routes

  // Dropdown menu for the nested dropdowns

  return (

    <>

      {mobile && (<Container sx={mobile ? visible ? { position: 'relative', top: 0, zIndex: 10 } : { display: 'none' }
        : sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
        <MKBox
          py={1}
          px={{ xs: 1, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
          my={relative ? 0 : 0}

          width="100%"
          height="auto"

          shadow={transparent ? "none" : "md"}
          color={light ? "white" : "black"}
          position={relative ? "relative" : "absolute"}
          left={0}
          zIndex={3}
          sx={lightMode ? ({ palette: { white }, functions: { rgba } }) => ({
            //backgroundColor: rgba(white.main, 0.8),
            backdropFilter: transparent ? "none" : `saturate(400%) blur(30px)`,
          }) : ({ palette: { black }, functions: { rgba } }) => ({
            //backgroundColor: rgba(black.main, 0.7),
            backdropFilter: transparent ? "none" : `saturate(400%) blur(30px)`,
          })}


        >
          <MKBox display="flex" justifyContent="space-between" alignItems="center" >
            <MKBox
              component={Link}
              to="/"
              lineHeight={1}
              py={mobile ? 1.5 : 3}
              pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
              display="flex"
              alignItems="center"
            >

              <img
                src={lightMode ? logoImg : logoImgDark}
                style={{
                  width: '10%',
                  marginRight: '10px'

                }}

              />

              <MKTypography
                variant="button"
                fontWeight="bold"
                color={lightMode ? "dark" : "white"}
                fontSize={22}

              // Adjust the value to your desired font size
              >
                {brand}
              </MKTypography>
            </MKBox >


            <MKBox display="flex" justifyContent="space-between" alignItems="center" >
              <MKTypography
                variant="button"
                fontWeight="bold"
                color={lightMode ? "dark" : "white"}
                fontSize={16}

              // Adjust the value to your desired font size
              >
                Dark
              </MKTypography>
              <Switch onChange={toggleMode} checked={!lightMode} />
            </MKBox>

          </MKBox>
        </MKBox>
      </Container>
      )}


      {!mobile && (

        <MKBox
          py={1}
          px={{ xs: 1, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}


          width="100%"
          height="auto"

          shadow={transparent ? "none" : "md"}
          color={light ? "white" : "black"}
          position={relative ? "relative" : "absolute"}
          left={0}
          zIndex={3}
          sx={lightMode ?
            {
              //background:  `linear-gradient(to bottom, #CCF8F3, rgba(200, 245, 230, 0.90))`,
                backdropFilter: transparent ? "none" : `saturate(400%) blur(30px)`
            }
            :
            {
              //background:  `#000`,
              backdropFilter: transparent ? "none" : `saturate(400%) blur(30px)`,
            }
          }


        >
          <MKBox display="flex" justifyContent="space-between" alignItems="center" >
            <MKBox
              component={Link}
              to="/"
              lineHeight={1}
              py={mobile ? 1.5 : 3}
              pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
              display="flex"
              alignItems="center"
            >

              <img
                src={lightMode ? logoImg : logoImgDark}
                style={{
                  width: '10%',
                  marginRight: '10px'

                }}

              />

              <MKTypography
                variant="button"
                fontWeight="bold"
                color={lightMode ? "dark" : "white"}
                fontSize={22}

              // Adjust the value to your desired font size
              >
                {brand}
              </MKTypography>
            </MKBox >


            <MKBox display="flex" justifyContent="space-between" alignItems="center" >
              <MKTypography
                variant="button"
                fontWeight="bold"
                color={lightMode ? "dark" : "white"}
                fontSize={16}

              // Adjust the value to your desired font size
              >
                Dark
              </MKTypography>
              <Switch onChange={toggleMode} checked={!lightMode} />
            </MKBox>

          </MKBox>
        </MKBox>

      )}


    </>

  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  brand: "Codea Academy",
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
