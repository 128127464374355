/* eslint-disable */
import React, { createContext, useContext, useState } from "react";
import { useMediaQuery } from '@mui/material';

const LightModeContext = createContext();

export const LightModeProvider = ({ children }) => {
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  const [lightMode, setLightMode] = useState(!isSmallScreen);

  return (
    <LightModeContext.Provider value={{ lightMode, setLightMode }}>
      {children}
    </LightModeContext.Provider>
  );
};

export const useLightMode = () => {
  return useContext(LightModeContext);
};