/* eslint-disable */
import React from 'react';
import Slider from 'react-slick';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//mport bgFront from "assets/images/rotateFBCom.jpg";
import javaImg from "assets/images/java.jpg";
import CImg from "assets/images/C.jpg";
import Csharp from "assets/images/Csharp.jpg";
import python from "assets/images/python.jpg";
import SEimg from "assets/images/SEimg.jpg";
import MLimg from "assets/images/ML.jpg";
import { useMediaQuery, createTheme } from '@mui/material';

const courseData = [
  {
    name: 'Java Programming',
    image: javaImg, // Replace with your image URL
    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '48%' }}>
            <strong>Basic Syntax of Java Language</strong><br />
            Print statement /
            Variable declaration and data types /
            Get user input /
            Operators /
            If-Else /
            Loops (For, While) /
            Arrays<br />
          </div>
          <div style={{ width: '48%' }}>
            <strong>Object Oriented Programming</strong><br />
            Class and Objects /
            Encapsulation /
            Inheritance /
            Abstract /
            Polymorphism<br />

          </div>
        </div>
        <div> <br />Any other topic regarding Java programming such as Java FX, Swing, or Design Patterns can be requested.</div>
      </>
    ),
    detailsMobileView: (
      <>
        <strong>Basic Syntax of Java Language</strong><br />
        ● Print statement<br />
        ● Variable declaration and data types<br />
        ● Get user input<br />
        ● Operators<br />
        ● If-Else<br />
        ● Loops (For, While)<br />
        ● Arrays<br />
        <strong>Object Oriented Programming</strong><br />
        ● Class and Objects<br />
        ● Encapsulation<br />
        ● Inheritance<br />
        ● Abstract<br />
        ● Polymorphism<br />
        <br />Any other topic regarding Java programming such as Java FX, Swing, or Design Patterns can be requested.
      </>
    )
  },
  {
    name: 'C Programming',
    image: CImg, // Replace with your image URL

    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <strong>Basic Syntax of C Language</strong><br />
            Print statement /
            Variable declaration and data types /
            Get user input /
            Operators /
            If-Else /
            Loops (For, While) /
            Arrays /
            Functions /
            File Handling
          </div>
        </div>
      </>

    ),
    detailsMobileView: (
      <>
        <strong>Basic Syntax of C Language</strong><br />
        ● Print statement<br />
        ● Variable declaration and data types<br />
        ● Get user input<br />
        ● Operators<br />
        ● If-Else<br />
        ● Loops (For, While)<br />
        ● Arrays<br />
        ● Functions<br />
        ● File Handling<br />
      </>
    )

  },
  {
    name: 'C# Programming',
    image: Csharp, // Replace with your image URL

    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '48%' }}>
            <strong>Basic Syntax of C# Language</strong><br />
            Print statement /
            Variable declaration and data types /
            Get user input /
            Operators /
            If-Else /
            Loops (For, While) /
            Arrays /
            Functions
          </div>
          <div style={{ width: '48%' }}>
            <strong>Object Oriented Programming</strong><br />
            Class and Objects /
            Encapsulation /
            Inheritance /
            Abstract /
            Polymorphism
          </div>
        </div>
        <div><br />Any other topic regarding C# programming such as GUI Creation using Windows Forms, and Design Patterns can be requested.</div>
      </>


    ),
    detailsMobileView: (
      <>
        <strong>Basic Syntax of C# Language</strong><br />
        ● Print statement<br />
        ● Variable declaration and data types<br />
        ● Get user input<br />
        ● Operators<br />
        ● If-Else<br />
        ● Loops (For, While)<br />
        ● Arrays<br />
        <strong>Object Oriented Programming</strong><br />
        ● Class and Objects<br />
        ● Encapsulation<br />
        ● Inheritance<br />
        ● Abstract<br />
        ● Polymorphism<br />
        <br />Any other topic regarding C# programming such as GUI Creation using Windows Forms, and Design Patterns can be requested.
      </>
    )
  },

  {
    name: 'Software Engineering',
    image: SEimg, // Replace with your image URL

    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <strong>SE Content Covered</strong><br />
            SDLC /
            Waterfall Model /
            Agile /
            Spiral /
            Comparisons between the models /
            UML Diagrams -
            Usecase, Class, Activity, Sequence / Case Studies 
          </div>
        </div>
        <div><br />Any other topic or area regarding Software Engineering can be requested.</div>
      </>


    ),
    detailsMobileView: (
      <>
        <strong>SE Content Covered</strong><br />
        ● SDLC<br />
        ● Waterfall Model<br />
        ● Agile<br />
        ● Spiral<br />
        ● Comparisons between the models<br />
         
        <strong>UML Diagrams</strong><br />
        ● Usecase<br />
        ● Class<br />
        ● Activity<br />
        ● Sequence<br />

        <br />● Case Studies<br />
        <br />Any other topic or area regarding Software Engineering can be requested.
      </>
    )
  },

  {
    name: 'Python Programming',
    image: python, // Replace with your image URL

    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <strong>Basic Syntax of Python Language</strong><br />
            Print statement /
            Variable declaration and data types /
            Get user input /
            Operators /
            If-Else /
            Loops (For, While) /
            Arrays /
            Functions /
            File Handling
          </div>
        </div>
        <div><br />Any other topic regarding Python programming can be requested.</div>
      </>



    ),
    detailsMobileView: (
      <>
        <strong>Basic Syntax of Python Language</strong><br />
        ● Print statement<br />
        ● Variable declaration and data types<br />
        ● Get user input<br />
        ● Operators<br />
        ● If-Else<br />
        ● Loops (For, While)<br />
        ● Arrays<br />
        ● Functions<br />
        ● File Handling<br />
        <br />Any other topic regarding Python programming can be requested.
      </>
    )
  },

  {
    name: 'Machine Learning (Practical)',
    image: MLimg, // Replace with your image URL

    detailsDesktopView: (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <strong>Machine Learning Practical Content</strong><br />
            Pre-processing Steps /
            Data Normalization /
            Ordinal Data convert to Nominal Data /
            Train the model /
            Test the model /
            Predict the values Through Model
          </div>
        </div>
        <div><br />Any other topic regarding machine learning such as algorithms can be requested.</div>
      </>




    ),
    detailsMobileView: (
      <>
        <strong>Machine Learning Practical Content</strong><br />
        ● Pre-processing Steps<br />
        ● Data Normalization<br />
        ● Ordinal Data convert to Nominal Data<br />
        ● Train the model<br />
        ● Test the model<br />
        ● Predict the values Through Model<br />
        <br />Any other topic regarding machine learning such as algorithms can be requested.
      </>
    )
  },


  // Add more course data as needed
];

const SlidingCardStack = ({ color, lightMode }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    adaptiveHeight: true,

  };

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Slider {...settings} >
      {courseData.map((course, index) => (
        <Card key={index} sx={{
          boxShadow: 'none', backgroundColor: 'rgba(0, 0, 0, 0.05)',
          border: lightMode ? '1.5px solid #9BAC9E' : '1.5px solid #894D00', minHeight: "550px"
        }} >
          <CardContent>
            <Typography variant="h5" color={color} component="div">
              {course.name}
            </Typography>
            <img
              src={course.image}
              style={{
                width: '100%',
                height: isMobile ? '200px' : '300px',
                objectFit: 'cover',
                borderRadius: '4px',
                marginBottom: '16px',
                marginTop: '6px',
                border: '0.1px solid #000',
              }}
              alt={course.name}
            />
            {isMobile ? (
              <div>
                <Typography color={color} variant="body2">
                  {course.detailsMobileView}
                </Typography>

                <Typography color={color} variant="body3">
                  <>
                    <br />
                    <strong>Pricing</strong><br />
                    Individual - 1500 per hour
                    <br /> Group - 800 per hour per person<br /> (Min. Group Size - 8) \
                    (Location - Around Maharagama)
                    <br />
                    <br /> <strong>Duration</strong> - 4 - 12 weeks (Duration can vary depending on the requested content.)
                    <br />
                    <br />

                  </>
                </Typography>
              </div>
            ) : (
              <div>
                <Typography color={color} variant="body2">
                  {course.detailsDesktopView}
                </Typography>

                <Typography color={color} variant="body3">
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '100%' }}>
                        <br />
                        <strong>Pricing</strong><br />
                        Individual - 1500 per hour
                        <br />  Group - 800 per hour per person (Min. Group Size - 8) \
                        (Location - Around Maharagama)
                        <br /> 
                        <br /> <strong>Duration</strong> - 4 - 12 weeks (Duration can vary depending on the requested content.)
                        <br /> 
                        <br /> 
                      </div>
                    </div>
                  </>

                </Typography>
              </div>
            )}




            <Link to={`/request-to-join?courseName=${encodeURIComponent(course.name)}`} style={{ textDecoration: 'none' }}>
              <Button variant="outlined"
                style={{
                  marginTop: '8px',
                  borderColor: lightMode ? '#000' : '#FFF', color: lightMode ? '#000' : '#FFF',
                  backgroundColor: lightMode ? '#FFF' : '#000'
                }}>
                Request to Join
              </Button>
            </Link>
            <Link to={`/request-for-assistance?courseName=${encodeURIComponent(course.name)}`}
              style={{ textDecoration: 'none' }}>
              <Button variant="outlined"
                style={{
                  marginTop: isMobile ? '18px' : '8px', marginLeft: isMobile ? '0px' : '30px',
                  borderColor: lightMode ? '#000' : '#FFF', color: lightMode ? '#000' : '#FFF',
                  backgroundColor: lightMode ? '#FFF' : '#000'
                }}>
                Request For Other Assistance
              </Button>
            </Link>
          </CardContent>
        </Card>
      ))}
    </Slider>
  );
};

export default SlidingCardStack;
