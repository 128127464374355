/* eslint-disable */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import VideoCard from "examples/Cards/VideoCards/Videocard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";

function DesignBlocks({color,colorDesc,backgroundColor,titleColor,videoHeight}) {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10, mt:-5}} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" color={color} fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color={colorDesc} mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ videoUrl,title }) => (
            <Grid item xs={12} md={6} sx={{ mb: 2 }} key={name}>
              <VideoCard videoSrc={videoUrl} title={title}  
                backgroundColor={backgroundColor}
                titleColor={titleColor} videoHeight={videoHeight} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={4} py={2}>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
