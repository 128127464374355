/* eslint-disable */
// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
// Forms page components
import FormReqAssist from "layouts/sections/input-areas/forms/components/ReqAssistance";
// Forms page components code

function Forms() {
  return (
    <BaseLayout
    >
      <FormReqAssist />
    </BaseLayout>
  );
}

export default Forms;
