/* eslint-disable */
import { Link } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useLocation } from 'react-router-dom';
import ReCAPTCHAEnterprise from "react-google-recaptcha";
import { useLightMode } from 'LightModeContext';
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


function FormSimple() {


  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    message: "",
    AcademicLevel: ""
  });

  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const { lightMode, setLightMode } = useLightMode();



  const [isWhatsappButtonDisabled, setWhatsappButtonDisabled] = useState(true);
  const [isEmailButtonDisabled, setEmailButtonDisabled] = useState(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseName = queryParams.get('courseName');


  const handleRecaptchaChange = (token) => {

    setReCaptchaToken(token);


    changeSubmitButtonState(token);

  };

  const changeSubmitButtonState = (reCaptchaToken) => {

    if (formData.firstName != "" && formData.AcademicLevel != ""
      && reCaptchaToken != "") {
      setWhatsappButtonDisabled(false);
      setEmailButtonDisabled(false);

    }

  }


  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const handleInputChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });

    changeSubmitButtonState(reCaptchaToken);
  };

  const createWhatsAppLink = () => {
    const { firstName, lastName,  message, AcademicLevel } = formData;
    const phoneNumber = "+94713283694";

    const encodedMessage = encodeURIComponent(`Request For Assistance: ${courseName}\nName: ${firstName} ${lastName}
    \nMessage: ${message}\n\nAcademicLevel: ${AcademicLevel}`);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  };

  const createEmailLink = () => {
    const { firstName, lastName, message, AcademicLevel } = formData;
    const to = 'info.codeaacademy@gmail.com'; // Replace with the desired recipient email address
    const subject = encodeURIComponent("Request For Assistance");
    const body = encodeURIComponent(`Request For Assistance: ${courseName}\nName: ${firstName} ${lastName}
    \nMessage: ${message}\n\nAcademicLevel: ${AcademicLevel}`);
    return `mailto:${to}?subject=${subject}&body=${body}`;
  };




  return (
    <MKBox component="section" py={10} >
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={3} color={lightMode ? "dark" : "light"}>
            Request For Assistance - {courseName}
          </MKTypography>
        </Grid>

        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autoComplete="off" >
            <MKBox>
              <Grid container spacing={3}>

                <Grid item>
                  <InputLabel id="select-option-label" required
                    sx={{ color: lightMode ? "" : "#FFF" }}>Select Academic Level</InputLabel>
                </Grid>

                <Grid item xs={12}>
                  <Select
                    labelId="select-option-label"
                    value={selectedOption}
                    onChange={(event) => {
                      handleSelectChange(event);
                      handleInputChange("AcademicLevel")(event);
                    }}
                    fullWidth
                    sx={{ height: '40px', color: lightMode ? "#000" : "#FFF" }}
                    defaultValue=""
                    renderValue={(selected) => (
                      <div style={{ color: lightMode ? '#000' : '#FFF' }}>{selected}</div>
                      // Replace 'your_selected_color' with the color you want for the selected item
                    )}
                  >

                    <MenuItem value="A/L ICT">A/L ICT</MenuItem>
                    <MenuItem value="B.Sc.">B.Sc.</MenuItem>
                    <MenuItem value="M.Sc.">M.Sc.</MenuItem>

                  </Select>
                </Grid>

                <Grid item xs={12} md={6}>

                  <MKInput variant="standard" label="First Name" required
                    InputLabelProps={{
                      style: { color: lightMode ? '#858585' : '#FFF' },
                    }}
                    InputProps={{
                      style: { color: lightMode ? '#000' : '#FFF' },
                    }}
                    fullWidth onChange={handleInputChange("firstName")}

                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput variant="standard" label="Last Name" 
                    InputLabelProps={{
                      style: { color: lightMode ? '#858585' : '#FFF' },
                    }}
                    InputProps={{
                      style: { color: lightMode ? '#000' : '#FFF' },
                    }}
                    fullWidth onChange={handleInputChange("lastName")} />
                </Grid>
                <Grid item xs={12}>
                  <MKInput variant="standard" label="Custom Message"
                    InputLabelProps={{
                      style: { color: lightMode ? '#858585' : '#FFF' },
                    }}
                    InputProps={{
                      style: { color: lightMode ? '#000' : '#FFF' },
                    }}
                    fullWidth onChange={handleInputChange("message")} />
                </Grid>
              </Grid>
              <Grid mt={6}>
                <ReCAPTCHAEnterprise
                  sitekey="6LdykDkpAAAAAAdtGEHlt9xfMMk1pQmHA_Xljf5O"
                  onChange={handleRecaptchaChange}
                />
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={4} mt={6}>
                <MKButton type="submit" variant="gradient" color="success"
                  component="a"
                  href={createWhatsAppLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={isWhatsappButtonDisabled}

                  fullWidth>
                  Send Via WhatsApp
                </MKButton>

              </Grid>

              <Grid container item justifyContent="center" xs={12} my={4}>
                <MKButton type="submit" variant="gradient" color="warning"

                  component="a"
                  href={createEmailLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={isEmailButtonDisabled}

                  fullWidth>
                  Send Via Email
                </MKButton>

              </Grid>

              <Grid container item justifyContent="center" xs={12} my={4}>
                <MKBox width="100%"
                  component={Link}
                  to="/"
                >
                  <MKButton type="submit" variant="gradient" color="light"
                    component="a"
                    fullWidth>
                    Cancel and Go Back
                  </MKButton>

                </MKBox >


              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FormSimple;
