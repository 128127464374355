/* eslint-disable */
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useMediaQuery, createTheme } from '@mui/material';

const SimpleCard = ({ title, description, image, description2, description3, description4, description5,
  description6, description7, description8, description9 }) => {
  const cardStyles = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white", // You might want to adjust the text color based on the background
    display: "flex",
    flexDirection: "column", // Content will be stacked vertically
    justifyContent: "flex-start", // Align content at the top
    minHeight: "400px", // Set a minimum height to prevent content from being too small
    border: '0.1px solid #046661',
  };

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card style={cardStyles}>
      <CardContent>




        <MKBox pt={isMobile ? 6 : 6} pb={isMobile ? 3 : 3}  textAlign="center" lineHeight={1}>
          <MKTypography variant={isMobile ? "h3" : "h2"} color="warning" gutterBottom
            sx={{ userSelect: 'none', fontSize: isMobile ? '2.3rem' : '2.3rem' }}>
            {title}
          </MKTypography>



          <Card
            sx={{
              
              borderRadius: 2,
              background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))`,
              //backgroundColor: lightMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
              backdropFilter: "saturate(50%) blur(20px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              px: 3,
              mt: isMobile? 8 : 6,
              border: '0.5px solid #FFF',
            }}

          >
            <MKTypography mt={6} variant="body1" color="white" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '1.3rem' : '1.3rem' }}>
              {description}
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '0.98rem' : '1.02rem' }}>
              {description2}
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '0.98rem' : '1.02rem' }}>
              {description3}
            </MKTypography>


            <MKTypography mt={6} variant="body1" color="darkgreen" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '1.05rem' : '1.13rem' }}>
              {description4}
              <MKTypography mt={1} variant="body2" color="white" opacity={1}
                sx={{ userSelect: 'none', fontSize: isMobile ? '0.85rem' : '0.92rem' }}>
                {description5}
              </MKTypography>
            </MKTypography>
            <MKTypography mt={1} variant="body2" color="white" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '0.85rem' : '0.92rem' }}>
              {description6}
            </MKTypography>
            

            <MKTypography mt={6} variant="body1" color="darkgreen" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '1.05rem' : '1.13rem' }}>
              {description7}
              <MKTypography mt={1} variant="body2" color="white" opacity={1}
                sx={{ userSelect: 'none', fontSize: isMobile ? '0.85rem' : '0.92rem' }}>
                {description8}
              </MKTypography>
            </MKTypography>
            <MKTypography mt={1} mb={6} variant="body2" color="white" opacity={1}
              sx={{ userSelect: 'none', fontSize: isMobile ? '0.85rem' : '0.92rem' }}>
              {description9}
            </MKTypography>
          </Card>

        </MKBox>


      </CardContent>
    </Card>
  );
};

export default SimpleCard;