/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Example Projects",
    description: "Learn with practical hands-on coding",
    items: [
      {
        title: "Vehicle Management System (C#)",
        videoUrl: "https://drive.google.com/file/d/1YVXd-_MggYTLOlLXuDCrlIC5ZTfx5cju/preview",
      
      },

      {
        title: "Bank Management System (C)",
        videoUrl: "https://drive.google.com/file/d/1077-rCJpDt6X_S3y3pymipiwmOec9bA3/preview",
      
      },

      {
        title: "TicTacToe 9x9 Game (Java Swing)",
        videoUrl: "https://drive.google.com/file/d/1EsBW09EG12QsQNhZ3C7tj6U-J-ilfTGR/preview",
      
      },

      {
        title: "Amazon Reviews Analyzer - NLP (Python)",
        videoUrl: "https://drive.google.com/file/d/1AG7p06T4-iEWGVfiGaG5Ervb0WhYR9x-/preview",
      
      },
    ],
  },
];
