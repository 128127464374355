/* eslint-disable */
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-countup component

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SchoolIcon from '@mui/icons-material/School';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

function DetailCard({ heading, color, title, description, colorTitle, colorDesc, bgImg, descPadding, isMobile, sideImg, left }) {
    return (


        <MKBox position="relative" width="100%" height={isMobile ? "700px" : "550px"} overflow="hidden">


            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: color, }}>



                <MKBox

                    textAlign="center"

                >

                    {left && (
                        <Container>
                            <Grid container spacing={isMobile ? 6 : 0} >
                                <Grid item xs={12} lg={3} sx={{ mx: "auto" }}>
                                    <img src={sideImg} alt="Image" style={{ position: 'absolute', 
                                    left: 0, top: isMobile ? 380 : 30, width: isMobile ? "100%" : "50%", 
                                    opacity: isMobile ? 0.18 : 0.7 }} />
                                </Grid>
                                <Grid item xs={12} lg={7}  sx={{ ml: "auto" }}>
                                    <MKTypography color="light"ml={isMobile ? 0 : 10} variant="h2" textGradient pt={isMobile ? 1 : 10}>
                                        {heading}
                                    </MKTypography>
                                    {title && (
                                        <MKTypography color={colorTitle} variant="h6" ml={isMobile ? 0 : 10} mt={2} mb={1} p={2}>
                                            {title}
                                        </MKTypography>
                                    )}
                                    {description && (
                                        <MKTypography color={colorDesc} variant="body2" ml={isMobile ? 0 : 10} px={descPadding}>
                                            {description}
                                        </MKTypography>
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                    )}

                    {!left && (
                        <Container>
                            <Grid container spacing={isMobile ? 6 : 0} lg={9}>
                                <Grid item xs={12} lg={7} sx={{ mx: "auto" }}>
                                    <MKTypography color="light" variant="h2" textGradient pt={isMobile ? 5 : 10}>
                                        {heading}
                                    </MKTypography>
                                    {title && (
                                        <MKTypography color={colorTitle} variant="h6"  mt={2} mb={1} p={1}>
                                            {title}
                                        </MKTypography>
                                    )}
                                    {description && (
                                        <MKTypography color={colorDesc} variant="body2" px={descPadding}>
                                            {description}
                                        </MKTypography>
                                    )}



                                </Grid>
                                <Grid item xs={12} lg={5} sx={{ ml: "auto" }}>
                                    <img src={sideImg} alt="Image" style={{ position: 'absolute',
                                     right: 20, top: isMobile ? 300 : 30, width: isMobile ? "100%" : "50%", 
                                     opacity: isMobile ? 0.2 : 0.8 }} />
                                </Grid>
                            </Grid>
                        </Container>
                    )}

                </MKBox>
            </div>
        </MKBox>

    );
}

// Setting default props for the DefaultCounterCard
DetailCard.defaultProps = {
    color: "dark",
    description: "",
    title: "",
};

// Typechecking props for the DefaultCounterCard
DetailCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    heading: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default DetailCard;
